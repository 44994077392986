import React, { FC } from 'react';

import Link from 'common/Link';

import Accordion from '../Accordion';
import { ListTreeProps, SiteMapLinkType } from './model';

import './ListTree.scss';

const ListTree: FC<ListTreeProps> = ({ items }) => {
  const renderLinks = (data: SiteMapLinkType) => {
    if (!data?.children.length) {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      const singleLinkElement = [<Link url={data.path}>{data.title.toUpperCase()}</Link>];

      return (
        <Accordion
          key={data.id}
          title={data.title}
          elements={singleLinkElement}
          customClass="ListTree"
        />
      );
    }

    const multiLinksElement = data.children.map((child) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link key={child.id} url={child.path}>
        {child.title.toUpperCase()}
      </Link>
    ));

    return (
      <Accordion
        key={data.id}
        title={data.title}
        elements={multiLinksElement}
        customClass="ListTree__child"
      />
    );
  };

  return (
    <>
      {items[0].children.map((child) => (
        <div key={child.id}>{renderLinks(child)}</div>
      ))}
    </>
  );
};

export default ListTree;
